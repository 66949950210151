var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-code',{staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"title":"ساخت صفحه محتوای متنی"}},[(_vm.loading)?_c('LoadingComponent'):_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"basicInput"}},[_c('h4',[_vm._v("عنوان صفحه")]),_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"title","id":"title","state":errors.length > 0 ? false : null,"placeholder":"عنوان صفحه"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "این فیلد الزامی است" : ""))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"basicInput"}},[_c('h4',[_vm._v("اسلاگ")]),_c('validation-provider',{attrs:{"name":"slug","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"slug","id":"slug","state":errors.length > 0 ? false : null,"placeholder":"اسلاگ"},model:{value:(_vm.formData.slug),callback:function ($$v) {_vm.$set(_vm.formData, "slug", $$v)},expression:"formData.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "این فیلد الزامی است" : ""))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('h4',[_vm._v("توضیحات کامل")]),_c('tiptap',{attrs:{"order":1},model:{value:(_vm.formData.body),callback:function ($$v) {_vm.$set(_vm.formData, "body", $$v)},expression:"formData.body"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('h4',[_vm._v("توضیحات")]),_c('validation-provider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"name":"description","id":"textarea-no-resize","placeholder":"توضیحات مختصری برای محصول خود وارد کنید، این توضیحات باید حداکثر 150 کاراکتر باشد","rows":"3","no-resize":""},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "این فیلد الزامی است" : ""))])]}}],null,true)})],1)],1),_c('b-button',{staticClass:"my-2 d-flex justify-content-center align-items-center",attrs:{"disabled":invalid || _vm.isLoading,"variant":"primary"},on:{"click":function($event){return _vm.updatePage()}}},[_vm._v(" ثبت "),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"position-absolute",attrs:{"small":"","type":"grow"}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }